<template>
  <div>
    <BaseLoading style="min-height: 400px;height: 90vh;" v-if="loading"></BaseLoading>

    <div v-else class="text-left">
      <BaseHeader :title="'My Wallet'"></BaseHeader>

      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
        
          <h3>Tips</h3>
          <div class="ml-auto btn-group">
            <button class="btn bg-teal" data-toggle="modal" data-target="#tipModal" @click="_setPaymentUrl('wallet')">Tip Writer From Wallet</button>
            <button class="btn btn-primary" data-toggle="modal" data-target="#tipModal" @click="_setPaymentUrl('gateway')">Tip Writer</button>
            
          </div>
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="text-left">
                <th>#</th>
                <th>Date</th>
                <th>Order Id</th>
                <th>Writer Id</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody v-if="tips.length > 0">
              <tr v-for="(tip, index) in tips" :key="index + 'ords'">
                <td class="text-left">{{ index+1 }}</td>
                <td class="text-left">
                  <!-- <router-link :to="{name: 'tip', params: { id: tip.id }}"></router-link>  -->
                  {{ tip.create_stamp || "" }}
                </td>
                <td class="text-left">{{ tip.order_id || "" }}</td>
                <td class="text-left">{{ tip.writer_id || "" }}</td>
                <td class="text-left" style="background-color: #b3d6b5;">${{ tip.amount || 0 }}</td>
              </tr>
            </tbody>
            <tfoot v-else>
              <tr>
                <td colspan="5">
                  <strong>No tip entries.</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!-- tip writer -->
    <BaseModal :title="'Tip Writer'" :modalId="'tipModal'">
      <TipWriter></TipWriter>
    </BaseModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import TipWriter from "../../components/payments/modals/TipWriter.vue";
export default {
  name: "Tips",
  components: {
    TipWriter,
  },
  computed: {
    ...mapState("payment", ["tips", "loading"]),
  },
  async mounted() {
    await this._getTipEntries();
  },
  methods: {
    ...mapActions("payment", ["_getTipEntries"]),
    ...mapMutations('payment', ["setPaymentUrl"]),
    _setPaymentUrl(action) {
      let paymentUrl = process.env.VUE_APP_API_BASE_URL;
      if (action === 'wallet') {
        paymentUrl = `${paymentUrl}/Checkout/WalletCheckout`
      } else {
        paymentUrl = `${paymentUrl}/Checkout/GetUrl`
      }
      console.log("paymentUrl", paymentUrl);
      this.setPaymentUrl(paymentUrl)
    }
  },
};
</script>
